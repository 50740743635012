var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.abilities,"select-options":{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
  }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'other')?_c('span',_vm._l((props.row.items.filter(object => !['create', 'read', 'update', 'delete'].includes(object.action))),function(eachPermission){return _c('b-form-checkbox',{key:eachPermission.action,attrs:{"checked":eachPermission.checked},on:{"change":function($event){return _vm.changeCell(props.column.field, eachPermission.action, props.row.originalIndex)}}},[_vm._v(_vm._s(eachPermission.actionName))])}),1):(props.column.field !== 'title')?_c('span',[(_vm.editable === true && props.row.items.filter(item => item.action === props.column.field).length > 0)?_c('b-form-checkbox',{attrs:{"checked":props.row.items.filter(item => item.action === props.column.field).length > 0 ? props.row.items.filter(item => item.action === props.column.field)[0].checked : false},on:{"change":function($event){return _vm.changeCell(props.column.field, 'quantity', props.row.originalIndex)}}},[_vm._v(_vm._s(props.column.label))]):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"loadingContent"},[_c('b-spinner',{attrs:{"label":"Loading","type":"grow"}})],1),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" "+_vm._s(_vm.t('Không có bản ghi nào!'))+" ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }